<template>
  <div class="container">
    <div class="section">
      <div v-if="$store.state.admin.logged">
        <div>Connecté</div>
        <button class="button is-danger" @click="seDeconnecter">Se déconnecter</button>
      </div>
      <div v-else>
        <div>Non connecté</div>
        <button class="button is-success" @click="seConnecter">Se connecter</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Admin",
  mounted() {
    console.log(this.$route.query.payload)
    return;
    if (this.$route.query.payload) {
      let payload = JSON.parse(this.$route.query.payload);
      api
        .post(`jwt`, { token: payload.token, cle: "admin" })
        .then((response) => {
          if (response.data.member) {
            this.$store.commit("setAdmin", response.data.member);
            this.$router.push({name:'Admin',params: {site:this.site}})
          }
        });
    }
  },
  methods: {
    seDeconnecter() {
      this.$store.commit("setAdmin", false);
    },
    seConnecter() {
      if (!this.$store.state.admin.logged) {
        let url = process.env.VUE_APP_JWT_URL;
        url = url.replace(
          "%url%",
          encodeURI(document.location.href.split("?")[0])
        );
        url = url.replace("%cle%", "admin");
        document.location.href = url;
      }
    },
  },
};
</script>